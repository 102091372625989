import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import styled from "styled-components"
import Navigation from "../navigation/Navigation"

const theme = {
  colors: {
    black: "#333",
    pink: `#d21c66`,
  },
  sizes: {
    mobile: `(max-width: 767px)`,
    tablet: `(min-width: 768px) and (max-width: 1024px)`,
    desktop: `(min-width: 1025px)`,
  },
}

const Header = styled.header`
  padding: 60px 0 60px 0;
`

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 800px;
  backgroundcolor: red;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  padding: 20px;
  padding-bottom: 150px;
`

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  li,
  p,
  pre,
  blockquote,
  figure,
  hr {
    margin: 0;
    padding: 0;
  }

  ol {
    list-style-position: inside;
  }

  ul {
    list-style: none;
  }

  embed,
  iframe,
  img,
  object,
  video {
    display: block;
    max-width: 100%;
  }

  [hidden] {
    display: none;
  }

  noscript {
    display: block;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  a,
  a:visited,
  a:link {
    color: ${({ theme }) => theme.colors.pink};
  }

  a:hover {
    text-decoration: underline;
  } 

  .gatsby-highlight[data-language] {
    margin-bottom: 30px;
  }
`

const BasicLayout = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            subtitle
          }
        }
      }
    `
  )

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Container>
          <Navigation />
          <Header>
            <Link to="/">
              <h2>{data.site.siteMetadata.title}</h2>
            </Link>
            <h5>{data.site.siteMetadata.subtitle}</h5>
          </Header>
          {children}
        </Container>
      </ThemeProvider>
    </>
  )
}

export default BasicLayout
