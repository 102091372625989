import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const List = styled.ul`
  display: flex;
  flex-flow: row nowrap;
`

const Item = styled.li`
  margin-right: 10px;
`

const A = styled.a`
  font-size: 15px;
`

const A2 = styled(Link)`
  font-size: 15px;
`

const Navigation = () => (
  <nav>
    <List>
      <Item>
        <A2 to="/">Home</A2>
      </Item>
      <Item>
        <A href="mailto:rffaguiar@gmail.com">Say Hello</A>
      </Item>
    </List>
  </nav>
)

export default Navigation
